<template>
  <v-row>
    <v-col cols="12" :class="status ? 'px-3 px-lg-3' : null">
      <div v-if="status && statusCustomer" id="order-list">
        <v-progress-circular v-if="pageLoading" class="el-center" :size="120" indeterminate>
          <span class="text-caption">Yükleniyor</span>
        </v-progress-circular>
        <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" class="pb-3" />

        <order-header></order-header>
        <order-content
          class="px-3"
          :kondisyon-deger="kondisyonDeger"
          :kondisyon-items="kondisyonItems"
          @changeStatus="pageLoadingStatus"
        ></order-content>
        <order-footer></order-footer>
      </div>
      <div v-else>
        <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />

        <v-alert
          v-if="!$store.state.plasiyerCustomer"
          text
          outlined
          class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption"
          type="warning"
        >
          Lütfen yukarıdan bir müşteri seçin
        </v-alert>
        <div v-else>
          <v-row>
            <v-col>
              <v-card
                :elevation="$vuetify.breakpoint.smAndDown ? '0' : '3'"
                rounded="xl"
                class="mx-auto mt-md-9"
                :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
                :max-width="$vuetify.breakpoint.smAndDown ? '92%' : 600"
              >
                <v-img
                  class="white--text align-center"
                  :class="$vuetify.breakpoint.smAndDown ? 'rounded-xl' : 'rounded-xl'"
                  src="@/assets/images/pages/header-01.jpg"
                  gradient="to bottom, rgba(0,0,0,0), rgba(49,45,75,1)"
                >
                  <div class="px-6">
                    <div class="py-5">
                      <div class="text-h6">İlave Bilgiler</div>
                      <div class="text-caption">Lütfen Aşağıdan Seçiniz</div>
                    </div>

                    <!-- <v-select
                      v-model="kotaDeger"
                      label="Kota Seçiniz"
                      :items="kotaItems"
                      @change="kotaChange"
                    >
                      <template v-slot:item="data">
                        <div class="my-3">
                          <div>
                            {{ data.item.text }}
                          </div>
                          <div
                            v-if="data.item.limit > 0"
                            class="text-caption"
                          >
                            <div class="secondary--text mr-3">
                              Limit : {{ data.item.limit | currency({ symbol: 'KG', fractionCount: '0' }) }}
                            </div>
                            <div class="warning--text mr-3">
                              Kullanılan : {{ data.item.sKullanilan | currency({ symbol: 'KG', fractionCount: '0' }) }}
                            </div>
                            <div class="error--text">
                              Kalan :
                              {{
                                (data.item.limit - data.item.sKullanilan)
                                  | currency({ symbol: 'KG', fractionCount: '0' })
                              }}
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-slot:selection="data">
                        <div class="textColor">
                          {{ data.item.text }}
                        </div>
                      </template>
                    </v-select>
                    -->
                    <div>
                      <v-select v-model="tipDeger" label="Sipariş Tipi Seçiniz" :items="tipItems" @change="tipChange">
                        <template #selection="{ item }">
                          <v-list-item-content class="textColor">{{ item.text }}</v-list-item-content>
                        </template>
                      </v-select>
                    </div>

                    <v-select
                      v-model="kondisyonDeger"
                      :disabled="!tipDeger"
                      label="Ödeme Tipi Seçiniz"
                      :items="kondisyonItems"
                      @change="kondisyonChange"
                    >
                      <template #selection="{ item }">
                        <v-list-item-content class="textColor">{{ item.text }}</v-list-item-content>
                      </template>
                    </v-select>
                    <v-btn block depressed rounded color="error" x-large class="my-6" @click="ilaveDevam">
                      Devam
                    </v-btn>
                  </div>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import useAppConfig from '@core/@app-config/useAppConfig'
import {
  mdiAccount,
  mdiEye,
  mdiFileExcel,
  mdiFilter,
  mdiFilterOff,
  mdiFullscreen,
  mdiFullscreenExit,
  mdiMagnify,
  mdiMessage,
  mdiPencil,
  mdiPlus,
  mdiPrinter,
  mdiSend,
  mdiShare,
  mdiShield,
  mdiShieldHalfFull,
  mdiSquareEditOutline,
  mdiWeatherNight,
  mdiWeatherSunny,
} from '@mdi/js'
import { postData } from '@utils'
import { onMounted, ref, watch } from '@vue/composition-api'

import CustomerList from '@/components/CustomerList.vue'
import orderStoreModule from '../orderStoreModule'
import OrderContent from './order-components/OrderContent.vue'
import OrderFooter from './order-components/OrderFooter.vue'
import OrderHeader from './order-components/OrderHeader.vue'

export default {
  components: {
    OrderHeader,
    OrderContent,
    OrderFooter,
    CustomerList,
  },
  setup() {
    const ORDER_APP_STORE_MODULE_NAME = 'app-order'
    const { isDark } = useAppConfig()
    const userData = JSON.parse(localStorage.getItem('userData'))
    const status = ref(false)

    const pageLoading = ref(true)

    // Register module
    if (!store.hasModule(ORDER_APP_STORE_MODULE_NAME)) {
      store.registerModule(ORDER_APP_STORE_MODULE_NAME, orderStoreModule)
    }

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(ORDER_APP_STORE_MODULE_NAME)) store.unregisterModule(ORDER_APP_STORE_MODULE_NAME)
    // })
    const statusCustomer = ref(false)
    const kotaItems = ref([])
    const kotaDeger = ref()
    const kondisyonItems = ref([])
    const kondisyonDeger = ref()
    const tipItems = ref([
      { text: 'Ön Ödemelli', value: 'O1P' },
      { text: 'Normal', value: 'O1' },
      { text: 'Bağlantılı (Yapım Aşamasında)', value: 'O9' },
    ])
    const tipDeger = ref()
    const delivery_date = store.state['app-order'].deliveryDate
    const plasyirFn = () => {
      postData({
        method: 'getKotaList',
        delivery_date,
      }).then(response => {
        kotaItems.value = []
        if (response.error === 0) {
          kotaItems.value = response.response.map(({ limit, sKullanilan, limitNum, notes }) => ({
            text: notes,
            value: limitNum,
            limit,
            sKullanilan,
          }))
        }
        kotaItems.value.unshift({
          text: 'Kota Kullanmadan Devam Et',
          value: '0',
        })
      })

      postData({
        method: 'getMemberPaymentConditions',
        customer: store.state.plasiyerCustomer,
      }).then(response => {
        kondisyonItems.value = []
        kondisyonItems.value = response.map(({ paymcondtxt, paymcond }) => ({
          text: paymcondtxt,
          value: paymcond,
        }))
      })
    }
    onMounted(() => {
      if (userData.role === 'PLSYR') {
        plasyirFn()
      }
      if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
        status.value = false
      } else if (userData.role === 'CUST') {
        status.value = true
        statusCustomer.value = true
      }
    })

    const kotaChange = () => {
      postData({
        method: 'setMemberKota',
        limitnum: kotaDeger.value,
        delivery_date,
      }).then(response => {})
    }

    const kondisyonChange = () => {
      postData({
        method: 'setMemberCondition',
        condition: kondisyonDeger.value,
      }).then(response => {})
    }
    const tipChange = () => {
      postData({
        method: 'changeOrderType',
        orderType: tipDeger.value,
      }).then(response => {
        // kondisyonItems
        store.commit('PAYMENT_TYPE', tipDeger.value)
        kondisyonItems.value = []
        kondisyonDeger.value = null
        for (const [key, value] of Object.entries(response.detail)) {
          kondisyonItems.value.push({
            text: value,
            value: key,
          })
        }
      })
    }
    const pageLoadingStatus = () => {
      pageLoading.value = false
    }
    watch(
      () => store.state.plasiyerCustomer,
      () => {
        kondisyonItems.value = []
        kotaItems.value = []
        kondisyonDeger.value = ''
        kotaDeger.value = ''
        tipDeger.value = ''
        statusCustomer.value = false
        plasyirFn()
      },
    )
    const isAddNewUserSidebarActive = ref(false)

    const ilaveDevam = () => {
      //değişen yer  (kotaDeger.value && kondisyonDeger.value && tipDeger.value)
      if (kondisyonDeger.value && tipDeger.value) {
        status.value = true
        statusCustomer.value = true
      }
    }

    return {
      isDark,
      isAddNewUserSidebarActive,
      status,
      pageLoading,
      pageLoadingStatus,
      userData,
      statusCustomer,
      kotaDeger,
      kotaChange,
      kotaItems,
      kondisyonItems,
      kondisyonDeger,
      kondisyonChange,
      tipItems,
      tipDeger,
      tipChange,

      ilaveDevam,

      // icons
      icons: {
        mdiFileExcel,
        mdiMessage,
        mdiWeatherNight,
        mdiWeatherSunny,
        mdiSquareEditOutline,
        mdiPencil,
        mdiPrinter,
        mdiAccount,
        mdiPlus,
        mdiEye,
        mdiMagnify,
        mdiShare,
        mdiFilter,
        mdiFullscreen,
        mdiFullscreenExit,
        mdiShield,
        mdiSend,
        mdiShieldHalfFull,
        mdiFilterOff,
      },
    }
  },
  data() {
    return {
      date: null,
      modal: false,
      fullscreenStatus: false,
      filterStatus: false,
      dataTableHeight: '',
      height: {
        system: 0,
        top: 87,
        footer: 47,
      },
    }
  },
}
</script>

<style>
.textColor {
  color: white;
}
.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  color: white !important;
}

.theme--light.v-label {
  color: rgb(206, 206, 206);
}

.theme--light.v-select .v-select__selection--comma {
  color: rgba(255, 255, 255, 0.87);
}
.v-select__selection {
  color: grey !important ;
}
</style>
