<template>
  <!-- <v-sheet
    class="mb-5 mt-1"
    color="transparent"
    rounded="lg"
  >
    <v-card
      class="d-flex overflow-hidden"
      rounded="lg"
      width="600"
      color="secondary"
    >
      <category-list></category-list>
      <plant-list></plant-list>
    </v-card>
  </v-sheet> -->
</template>
<script>
import CategoryList from './CategoryList.vue'
import PlantList from './PlantList.vue'

export default {
  components: { CategoryList, PlantList },
}
</script>
